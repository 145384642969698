
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FilesService } from './files.service';


@Injectable({ providedIn: 'root' })
export class LGFileService extends FilesService{

  constructor() { 
    super()
  }

  public getImage(path: string):  Promise<string>  {
    //console.log('getting image from LG');    
    return new Promise(resolve => {
      resolve(path);
    });
  }

  public getVideo(path: string):  Promise<string>  {
    return new Promise(resolve => {
      resolve(path);
    });
  }

  public saveImage(path:string):Promise<string>{
    return new Promise(resolve => {
      resolve(path);
    });
  }

  public saveVideo(path:string):Promise<string>{
    return new Promise(resolve => {
      resolve(path);
    });
  }

  public saveJsonFile(hash: string, body: any):Promise<void>{
    return new Promise(resolve => {
      resolve();
    });
  }

  public getJsonFile(path:string):Promise<string>{
    return new Promise(resolve => {
      resolve(path);
    });
  }

  public fileExists(hash: string): Promise<boolean> {
    return new Promise(resolve => {
      resolve(false);
    });
  }

  public filePathExists(fullpath: string): Promise<boolean> {
    return new Promise(resolve => {
      resolve(false);
    });
  }

  public deleteJsonFiles(): Promise<boolean> {
    return new Promise(resolve => {
      resolve(false);
    });
  }

  public deleteMediaFiles(): Promise<boolean> {
    return new Promise(resolve => {
      resolve(false);
    });
  }

  public appendLogFile(row: any): Promise<boolean> {
    return new Promise(resolve => {
      resolve(true);
    });
  }

  public openLogFile(): Promise<any[]> {
    return new Promise(resolve => {
      resolve([]);
    });
  }
  
  public getLicenseFile(): Promise<any> {
    return new Promise(resolve => {
      resolve({});
    });
  }

  public saveLicenseFile(body: any): Promise<void> {
    return new Promise(resolve => {
      resolve;
    });
  }

  public deleteLicenseFile(): Promise<boolean> {
    return new Promise(resolve => {
      resolve(false);
    });
  }

}
