import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from "@angular/core";
import { ComboService } from "../screen/layout/grid/combo.service";
import { TranslateService } from "@ngx-translate/core";
import { Widget } from "../models/widget.model";
import { Device } from '@ionic-native/device/ngx';
import * as DeviceFallback from '@capacitor/device';

import * as _ from 'lodash-es';
import { Subject } from "rxjs";



@Injectable({ providedIn: "root" })
export class CoreService {
    public ngUnsubscribe: Subject<void> = new Subject<void>();
    public deviceInfo;
    constructor(
        private translate: TranslateService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef,
        private ComboService: ComboService,
        private device:Device,
        
    ) { }

    /**
     * * Get Device Info.
     * @returns object device info
     */
    async getDeviceInfo():Promise<any> {
        return new Promise((resolve)=>{
            let id=this.device.serial;
            console.log('id is '+id);
            if(id==null || id=="unknown" || id==""){
                console.log('native failed going to fallback');
                DeviceFallback.Device.getId().then((deviceId)=>{
                    console.log('fallback id = '+deviceId.uuid);
                    
                    let info=DeviceFallback.Device.getInfo().then((deviceInfo)=>{
                        resolve({id:deviceId.uuid,...deviceInfo,mac_address:deviceId.uuid});
                    })
                })
            }else{
                console.log('platform is: '+this.device.platform)                
                let devInfo={name:this.device.model+this.device.version,model:this.device.model,platform:this.device.platform, manufacturer:this.device.manufacturer, serial:this.device.serial, mac_address:this.device.serial}
                resolve({id,...devInfo});
            }           
        })        
    }

    

    /**
     * * Get Random String Characters by length
     * @param length 
     * @returns random string characters
     */
    randomString(length: number) {
        let result = '', chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    /**
     * * Calculate initial box dimensions
     */
    calcBoxDimensions(custom?: any, programmaticPortrait?: boolean) {
        let boxX: number, boxY: number;

        //? 4:3 screen resolution
        if (_.isEqual(window.innerWidth / window.innerHeight, 4 / 3)) {
            boxX = window.innerWidth / 12;
            boxY = window.innerHeight / 9;
        } //? Native portait 9:16
        else if (_.isEqual(window.innerWidth / window.innerHeight, 9 / 16)) {
            boxX = window.innerWidth / 9;
            boxY = window.innerHeight / 16;
        
        } //? 9: 16 force to portrait
        else if (programmaticPortrait) {
            boxX = window.innerHeight / 9; 
			boxY = window.innerWidth / 16;
        }//? 16:9 screen or other
        else {
            if (!!custom) { //? custom layout
                boxX = window.innerWidth / custom.columns
                boxY = window.innerHeight / custom.rows
            } else {
                boxX = window.innerWidth / 16
                boxY = window.innerHeight / 9
            }
        }

        return { boxX, boxY };
    }

    /**
     * * Set Language
     * @param lang string
     */
    setLanguage(lang: string) {
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
    }

    /**
     * * Set DefultLang
     * @param lang 
     */
    setDefaultLang(lang: string) {
        this.translate.addLangs(["en", "it", "gr", "de", "ar", "es", "fr", "ru", "tr"]);
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
    }

    /**
     * * Generate Component dynamically
     * @param id 
     * @param widgetEl 
     * @returns 
     */
    generateComponent(widgetEl: HTMLElement, component: any, widget?: Widget, link?: string, combo?: boolean, index?: number) {
        if (!!widget) {
            let componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            let componentRef: ComponentRef<any> = componentFactory.create(this.injector);
            this.appRef.attachView(componentRef.hostView);

            let domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

            if (combo) {
                //? Combo Widget
                let comboIDs = widget.id.split('-');
                domElem.id = comboIDs[index];
                domElem.classList.add('combo-' + index);
                componentRef.instance.id = domElem.id;
                componentRef.instance.link=link
                componentRef.instance.extrenalLink = widget.link;
                console.log('combo link:'+link)
                componentRef.instance.combo = combo;
                componentRef.instance.counter = index;
                componentRef.instance.show = false;
                componentRef.instance.siblingId = _.isEqual(index, 0) ? comboIDs[1] : comboIDs[0];
                this.ComboService.saveInstaces(componentRef.instance, domElem.id);
                componentRef.hostView.reattach();

            } else {
                //? Simple Widget   
                componentRef.instance.id = widget.id;
                componentRef.instance.link = link;
                componentRef.instance.extrenalLink = widget.link;
                componentRef.instance.counter = widget.counter;
                componentRef.hostView.reattach();
            }

            widgetEl.appendChild(domElem);
            return widgetEl;
        }
    }

    /**
     * * Calculate Refresh Timestamp
     * @param refreshTime 
     * @returns timestamp
     */
    refreshTimeout(refreshTime: string) {
        let now = new Date();
        let then = new Date();
        let refreshHour = parseInt(refreshTime.split(':')[0]);
        let refreshMinute = parseInt(refreshTime.split(':')[1]);

        then.setHours(refreshHour);
        then.setMinutes(refreshMinute);
        then.setSeconds(0, 0);
        if (refreshHour < now.getHours() || (refreshHour == now.getHours() && refreshMinute <= now.getMinutes())) {
            then.setDate(then.getDate() + 1);
        }

        return then.getTime() - now.getTime();
    }

    /**
     * * Kill App Instance
     */
    killAppInstance(message: string) {
       throw new Error(message);
    }
}