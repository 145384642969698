import { getContent, getCurrency, getIframe, getLogo, getScreenLayout, getVideo, getWeather, setFakeOrientention, setFullContent } from "../state/app.actions";
import { AndroidService } from "../services/android.service";
import { CoreService } from "../services/core.service";
import { Widget } from "../models/widget.model";
import { filter, take } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";

@Injectable({ providedIn: "root" })
export class ScreenFacade {
    constructor(private store: Store, private Core: CoreService, private Android: AndroidService) { }

    /**
     * * Set Layout to state from OCG
     * @returns action to store layout to state
     */
    getScreenLayout(dispatch: boolean) {
        if (dispatch)
            this.store.dispatch(getScreenLayout());
        return this.store.select((state: any) => state.application.layout).pipe(filter(layout => !!layout), take(1));
    }

    /**
     * * Return Box Dimemsions
     * @returns Object Dimensions
     */
    getBoxDimensions(custom?: any, portrait?: boolean) {
        return this.Core.calcBoxDimensions(custom, portrait);
    }

    /**
     * * Change Content Language
     * @returns method to change lang
     */
    setLanguage() {
        this.store.select((state: any) => state.application.layout.language).subscribe(lang => {
            return this.Core.setLanguage(lang);
        })
        return this.Core.setLanguage('en');
    }

    /**
     * * Get Wigdets from State
     * @returns observable of widgets from state
     */
    getWidgets() {
        return this.store.select((state: any) => state.application.layout?.widgets).pipe(filter(state => !!state), take(1));
    }

    /**
     * * Greate Component base on id
     * @param widgetEl
     * @returns Component Ref
     */
    createComponent(widgetEl: HTMLElement, component: any, widget?: Widget, link?: string, combo?: boolean, index?: number) {
        return this.Core.generateComponent(widgetEl, component, widget, link, combo, index);
    }

    /**
     * * Get Logo for Logo Component
     * @returns logo path
     */
    getLogo() {  
        
        this.store.dispatch(getLogo());
        return this.store.select((state: any) => state.application.widgets?.logo);
    }

    /**
    * * Get Weather for Weather Component
    * @returns weather array
    */
    getWeather() {
        this.store.dispatch(getWeather());
        return this.store.select((state: any) => state.application.widgets?.weather);
    }

    /**
     * * Get Currency for Currency Component
     * @returns currency object
     */
    getCurrency() {
        this.store.dispatch(getCurrency());
        return this.store.select((state: any) => state.application.widgets?.currency);
    }

    /**
     * * Get Content for Slider Component
     * @returns content array
     */
    getContent(label: string, link: string) {
        this.store.dispatch(getContent({ payload: { label: label, link: link } }));
        return this.store.select((state: any) => state.application.widgets);
    }

    /**
     * * Get Fullscreen Videos for Video Component
     * @returns content array
     */
    getVideo() {
        this.store.dispatch(getVideo());
        return this.store.select((state: any) => state.application.widgets?.video);
    }

    /**
     * * Get Iframe for Iframe Component
     * @returns content array
     */
    getIframe() {
        this.store.dispatch(getIframe());
        return this.store.select((state: any) => state.application.widgets?.iframe);
    }

    /**
     * * Set Full Content from API
     * @returns content
     */
    getFullContent() {
        this.store.dispatch(setFullContent());
        return this.getStateFullContent();
    }

    /**
     * * Get Full Content from State
     * @returns content observable
     */
    getStateFullContent() {
        return this.store.select((state: any) => state.application.widgets);
    }

    /**
     * * Get Preview Mode from State
     * @returns boolean observable
     */
    getPreviewMode() {
        return this.store.select((state: any) => state.application.previewMode);
    }

    /**
     * * Open link App
     * @param link 
     * @returns open link (app / inAppBrowser)
     */
    openAppLink(link: string) {
        return this.Android.openApp(link);
    }

    /**
     * * Open link App
     * @param link 
     * @returns open link (app / inAppBrowser)
    */
    openBrowser(link: string) {
        return this.Android.openBrowser(link);
    }

    /**
     * * Set Fake Orientention
     * @param type 
     * @returns action setFakeOrientention
     */
    setFakeOrientention(type: string) {
        return this.store.dispatch(setFakeOrientention({payload: type}));
    }

    /**
     * * Get Fake Orientention
     * @returns Observable string
     */
    getFakeOrientention() {
        return this.store.select((state: any) => state.application.orientation);
    }
}