import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import _ from "lodash";
import { filter, take } from "rxjs/operators";
import { AppFacade } from "../app.facade";
import { Layout } from "../models/layout.model";
import { ScreenFacade } from "../screen/screen.facade";
import { fileServiceProvider } from "../services/file.service.provider";
import { FilesService } from "../services/files.service";

@Component({
    selector: 'app-prefetch',
    templateUrl: './prefetch.page.html',
    styleUrls: ['./prefetch.page.scss'],
    providers:[fileServiceProvider]
})
export class PrefetchPage implements OnInit {
    constructor(private fileService:FilesService, private ScreenFacade:ScreenFacade, private appFacade:AppFacade, private router:Router){}
    ngOnInit(): void {       
             
        this.ScreenFacade.getScreenLayout(true).pipe(take(10)).subscribe(layout => {   
            this.appFacade.getDomain().subscribe((dm) =>
                this.appFacade.enableSocketConnection()
            )        
            this.fetchFiles().then(()=>{
                this.router.navigate(['screen']);
            }).catch((err)=>console.log(err));
         })
        
   

    }
    async fetchFiles(){
        console.log('fetching Files');
        return new Promise((resolve)=>{
            Promise.all([this.fetchVideoFiles(),this.fetchFullVideoFiles(),this.fetchImageFiles()]).then(()=>{resolve(true)});            
        })
    }

    async fetchVideoFiles(){
        return new Promise((resolve)=>{
            this.ScreenFacade.getVideo().subscribe( async videos => {
                if (!_.isNull(videos) && !_.isEqual(videos, 'no data')) {
                    let videoFiles = _.cloneDeep(videos);
                    if(!!videos){                    
                        Promise.all(videoFiles.map(vf=>this.fileService.getVideo(vf.filename))).then((promiseArray)=>resolve(true))
                    }else{
                        resolve(true)
                    }
                }else{
                    resolve(true)
                }
            })     
    })
    }

    async fetchFullVideoFiles(){
        return new Promise((resolve)=>{
            this.ScreenFacade.getStateFullContent().pipe(                
                take(1)
            ).subscribe(content => {                
                if(!!content){
                    let videos = content['FullVideos'];                               
                    Promise.all(videos.map(vf=>this.fileService.getVideo(vf.filename))).then(()=>resolve(true))
                }else{
                    resolve(true)
                }
            })
        })
    }
    async fetchImageFiles(){       
        return new Promise((resolve)=>{
            this.ScreenFacade.getContent('Photo_Gallery','channel-content/getpluscontent&kind=5&checked=1&fullscreen=0&channel_id=').pipe(filter(cont=>cont?.Photo_Gallery!=null)).subscribe(content=>{
                console.log('fetching photos of photogallery');
                console.log(content);
                if(!!content?.Photo_Gallery && content?.Photo_Gallery!='no data')
                    Promise.all(content?.Photo_Gallery.map(pf=>this.fileService.getImage(pf.image))).then(()=>resolve(true))
                else
                    resolve(true);                
            })
        })
    }

}