import { createReducer, on } from "@ngrx/store";
import { Device } from "../models/device.model";
import { Layout } from "../models/layout.model";
import * as AppInitialActions from "./app.actions";

export interface State {
    orientation: string;
    previewMode: boolean;
    domain: string | null,
    device: Device | null;
    layout: Layout | null;
    widgets: any | null;
    online:boolean;
}

export const initialState: State = {
    orientation: 'landscape',
    previewMode: false,
    domain: null,
    device: null,
    layout: null,
    widgets: null,
    online:true
};

export const appReducer = createReducer(
    initialState,
    on(AppInitialActions.setDevice, (state, { payload }) => ({ ...state, device: payload })),
    on(AppInitialActions.setRegisterDevice, (state, { payload }) => ({ ...state, device: { ...state.device, exists: payload?.exists, key: payload?.key, deviceCouldBeRegister: payload?.register_device, alert: payload.alert } })),
    on(AppInitialActions.setScreenLayout, (state, { payload }) => ({ ...state, layout: payload })),
    on(AppInitialActions.setWidgets, (state, { payload }) => ({ ...state, widgets: { ...state.widgets, ...payload } })),
    on(AppInitialActions.togglePreviewMode, (state, { payload }) => ({ ...state, previewMode: payload })),
    on(AppInitialActions.setDomain, (state, { payload }) => ({ ...state, domain: payload })),
    on(AppInitialActions.setFakeOrientention, (state, { payload }) => ({ ...state, orientation: payload })),
    on(AppInitialActions.deviceOffline,(state)=>({...state, online:false})),
    on(AppInitialActions.deviceBackOnline,(state)=>({...state,online:true}))

)