import { AppLauncher } from '@capacitor/app-launcher';
import { Platform } from '@ionic/angular';
import { Browser, OpenOptions } from '@capacitor/browser';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';
import { Injectable } from "@angular/core";
import { StatusBar } from '@capacitor/status-bar';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import write_blob from 'capacitor-blob-writer';


//TO BE DELETED

@Injectable({ providedIn: "root" })
export class AndroidService {
    browser: any;

    constructor(
        private iab: InAppBrowser,
        private androidFullScreen: AndroidFullScreen,
        private platform: Platform
    ) {
        //? Hide status bar on android
        StatusBar.hide().catch(() => {});
        //? Full screen android
        androidFullScreen.immersiveMode();
        console.log('back button handler');
        
        this.platform.backButton.subscribeWithPriority(10000 , () => {
            console.log('back button pressed');            
        })
    }

    /**
     * * Open App
     * @param link 
     */
    async openApp(link: string) {
        if (!link) {
            return
        }
         let result = await AppLauncher.canOpenUrl({ url: link });
        console.log(result);
        
        
        if (result.value) {
            this.browser?.close();
            await AppLauncher.openUrl({ url: link });
        }
    }

    /**
     * * Open in app browser
     * @param link 
     */
     async openBrowser(link: string) {
        let iabOptions:InAppBrowserOptions;

        iabOptions = {
            footer: 'yes',
            location:'no',
            zoom:'no',
            hidenavigationbuttons: 'yes',
            toolbarposition: 'top',
            closebuttoncaption: 'Close',
            lefttoright: 'yes',
            toolbartranslucent: 'yes',
        }

        this.browser = this.iab.create(link,  '_blank', iabOptions);

        this.browser.on('loaderror')?.subscribe(() => {
            this.browser.close();
        });
        this.browser.on('exit')?.subscribe(() => {
            this.browser.close();
        });
    }


     async saveFileToStorage(url:string, filename:string) {
        let blob = await fetch(url).then((x) => x.blob());
        console.log(blob.size, filename);
        
        write_blob({
            path: `videos/${filename}`,
            directory: Directory.Data,
            blob: blob,
            recursive:true,
            on_fallback(error) {
                console.log(error);                
            }
        }).then((e) => {
            console.log(e);
            
        })
        
    }

    async readAndDisplay () {
        // const video_element = document.createElement("video");
        //   //video_element.style.position = 'relative';
        //   video_element.style.zIndex = '999999999';
        //   //video_element.autoplay = false;
        //   video_element.autoplay = false;
        //   video_element.loop = true
        //   video_element.width = 1280;
        //   setTimeout(() => {
        //     //video_element.load();
        //     //video_element.play();
        //   }, 2000)
        //let blob:Blob = await fetch('https://ocgstorage1.blob.core.windows.net/rnditc/videos/1577112648.mp4').then((x) => x.blob());
        Filesystem.getUri({
          path: "videos/1642593611.mp4",
          directory: Directory.Data
      }).then(async ({uri}) => {
        let capUrl = await Capacitor.convertFileSrc(uri);
        
          return capUrl;
        //   video_element.src = src;
        //   video_element.load();
        //   video_element.play().then(() => {
        //     document.getElementById('videotest')?.append(video_element);
         // });
          //video_element.src = 'https://ocgstorage1.blob.core.windows.net/rnditc/videos/1576242468.mp4';     
      });
      }



}