// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: '0.1.0',
  production: false,
  protocol: 'https://',
  passKey: 'bkY4X2hUIyFiUnc3X0FrNg==',
  isTv:false,
  deviceType:'web',
  platforms: [
    {name: 'web', code: 100},
    {name: 'android', code: 6},
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
