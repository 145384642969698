import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FilesService } from './files.service';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';
import { Capacitor } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';



@Injectable({ providedIn: 'root' })
export class AndroidFileService extends FilesService {

  constructor(private http: HttpClient) {
    super()
  }

  public async getImage(path: string): Promise<string> {
    //console.log('getting image from android');  
    return new Promise(resolve => {
      let url = path;
      let filename = 'images/' + path.replace(/^.*[\\\/]/, '')

      Filesystem.stat({
        directory: Directory.Data,
        path: filename
      }).then((statResult) => {
        resolve(Capacitor.convertFileSrc(statResult.uri))
      }).catch((err) => {
        console.log("errr" + err);
        this.saveImage(path);
        resolve(path);
      })

    })


  }

  public async getVideo(path: string): Promise<string> {
    return new Promise(resolve => {
      let filename = 'videos/' + path.replace(/^.*[\\\/]/, '')
      Filesystem.stat({
        directory: Directory.Data,
        path: filename
      }).then((statResult) => {
        console.log("Video stored and served");
        resolve(Capacitor.convertFileSrc(statResult.uri))
      }).catch((err) => {
        console.log("Video not stored - lets store it");
        this.saveVideo(path).then(_path => {
          console.log("Video just stored");
          //console.log(JSON.stringify(_path))
          resolve(Capacitor.convertFileSrc(_path));
        }).catch(err => {
          console.log(err);
          console.log("Can't store video");
          resolve(path);
        })
      })

    })
  }

  public async saveImage(path: string): Promise<string> {
    let filename = 'images/' + path.replace(/^.*[\\\/]/, '');
    console.log(filename);
    return new Promise(resolve => {
      this.http.get(path, { responseType: 'blob' }).subscribe((blob: Blob) => {
        console.log(blob);
        return write_blob({
          path: filename,
          directory: Directory.Data,
          blob: blob,
          recursive: true,
          on_fallback(error) {
            console.log(error);
          }
        }).then((file) => {
          resolve(file);
        }).catch(err => {
          console.log(err);
          resolve(path);
        })
      })
    })
  }

  public saveVideo(path: string): Promise<string> {
    let filename = 'videos/' + path.replace(/^.*[\\\/]/, '');
    console.log('saving video ' + filename);
    return new Promise(resolve => {
      this.http.get(path, { responseType: 'blob' }).subscribe((blob: Blob) => {
        console.log('blob:' + blob);
        return write_blob({
          path: filename,
          directory: Directory.Data,
          blob: blob,
          recursive: true,
          on_fallback(error) {
            console.log(error);
          }
        }).then((file) => {
          resolve(file);
        }).catch(err => {
          console.log(err);
          resolve(path);
        })
      })
    })
  }

  public getJsonFile(hash: string): Promise<any> {
    return new Promise(resolve => {
      Filesystem.readFile({
        path: 'json/' + hash + '.json',
        directory: Directory.Data,
        encoding: Encoding.UTF8
      }).then((contents) => {
        console.log(contents);
        resolve(JSON.parse(contents.data))
      }
      )
    })
  }

  
  public getLicenseFile(): Promise<any> {
    return new Promise(resolve => {
      Filesystem.readFile({
        path: 'license/ocgpro.json',
        directory: Directory.Data,
        encoding: Encoding.UTF8
      }).then((contents) => {
        //console.log(contents);
        resolve(JSON.parse(contents.data))
      }
      ).catch((error)=>{
        resolve(error);
      })
    })
  }

  public saveJsonFile(hash: string, body: any): Promise<void> {
    return new Promise((resolve) => {
      Filesystem.writeFile({
        path: 'json/' + hash + '.json',
        data: JSON.stringify(body),
        directory: Directory.Data,
        encoding: Encoding.UTF8,
        recursive: true
      }).then(() => { resolve() }).catch((error) => { throw new Error(error) })
    })
  }

  public saveLicenseFile(body: any): Promise<void> {
    return new Promise((resolve) => {
      Filesystem.writeFile({
        path: 'license/ocgpro.json',
        data: JSON.stringify(body),
        directory: Directory.Data,
        encoding: Encoding.UTF8,
        recursive: true
      }).then(() => { resolve() }).catch((error) => { throw new Error(error) })
    })
  }  

  public fileExists(hash: string): Promise<boolean> {
    return new Promise((resolve) => {
      Filesystem.stat({
        path: 'json/' + hash + '.json',
        directory: Directory.Data,
      }).then(() => { resolve(true) }).catch((error) => { resolve(false) })
    })
  }

  public filePathExists(fullpath: string): Promise<boolean> {
    return new Promise((resolve) => {
      Filesystem.stat({
        path: fullpath,
        directory: Directory.Data,
      }).then(() => { resolve(true) }).catch((error) => { console.log('file '+fullpath+' does not exist');resolve(false); })
    })
  }

  public deleteJsonFiles(): Promise<boolean> {
    return new Promise((resolve) => {
      Filesystem.readdir({ path: 'json/', directory: Directory.Data }).then(files => {
        Promise.all(files.files.map((file) => Filesystem.deleteFile({ path: 'json/' + file.name, directory: Directory.Data }))).then(() => resolve(true));
      })
    })
  }

  public deleteMediaFiles(): Promise<boolean> {
    return new Promise((resolve) => {
      Promise.all([this.deletePathFiles('videos'), this.deletePathFiles('images')]).then(() => resolve(true))
    })
  }

  public deleteLicenseFile(): Promise<boolean> {
    return new Promise((resolve) => {
      Filesystem.deleteFile({path:'license/ocgpro.json', directory: Directory.Data }).then(()=>resolve(true));     
    })
  }

  public deletePathFiles(path: string): Promise<boolean> {
    return new Promise((resolve) => {
      Filesystem.readdir({ path: path + '/', directory: Directory.Data }).then(files => {
        Promise.all(files.files.map((file) => Filesystem.deleteFile({ path: path + '/' + file.name, directory: Directory.Data }))).then(() => resolve(true));
      })
    })
  }

  public openLogFile(): Promise<any[]> {
    return new Promise((resolve) => {
      Filesystem.readFile({
        path: 'logs/playlog.json',
        directory: Directory.Data,
        encoding: Encoding.UTF8
      }).then((contents) => {
        console.log(contents);
        resolve(JSON.parse(contents.data))
      }
      )
    })
  }

  public appendLogFile(row: any): Promise<boolean> {
    return new Promise(resolve => {
      let finalData = [];
      Filesystem.stat({ path: 'logs/playlog.json', directory: Directory.Data }).then(() => {
        this.openLogFile().then((data) => {
          console.log('writing to log')
          console.log(data);
          if (data==null) {
            data = [];
          }
          data.push(row);
          finalData = data;
          Filesystem.writeFile({
            path: 'logs/playlog.json',
            data: JSON.stringify(finalData),
            directory: Directory.Data,
            encoding: Encoding.UTF8,
            recursive: true
          }).then(() => { resolve(true) }).catch((error) => { throw new Error(error) })
        })
      }).catch(() => {
        let data = [];
        data.push(row);
        finalData = data;
        Filesystem.writeFile({
          path: 'logs/playlog.json',
          data: JSON.stringify(finalData),
          directory: Directory.Data,
          encoding: Encoding.UTF8,
          recursive: true
        }).then(() => { resolve(true) }).catch((error) => { throw new Error(error) })
      })
    })
  }
}
