import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ComboService {
    comboInstances: Array<any> = [];

    /**
     * * Save Instance to an Array
     * @param instance 
     */
    saveInstaces(instance: any, id: string) {
        this.comboInstances[id] = instance;
    }

    /**
     * * Hide instance by index
     * @param id
     */
    hide(id: string) {
        this.comboInstances[id].show = false;
        if (!!this.comboInstances[id].slider) {
            this.comboInstances[id].slider.stopAutoplay();
            this.comboInstances[id].slider.slideTo(0);
            this.comboInstances[id].slider.update();
        }
    }

    /**
     * * Show instance by index
     * @param id 
     */
    show(id: string) {
        this.comboInstances[id].show = true;
        if (!!this.comboInstances[id].slider) {
            this.comboInstances[id].slider.startAutoplay();
        }
    }

    /**
     * * Combo Widget all exept slider
     * @param id 
     * @param siblingId 
     * @param delay 
     */
    nonSliderTransition(id:string, siblingId:string, delay: number) {
        let intervalTransition = setInterval(() => {
            this.hide(id);
            this.show(siblingId);

            let temp = id;
            id = siblingId;
            siblingId = temp;
        }, delay * 1000);
    }
}