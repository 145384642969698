import { deviceShowInfo, getRegisterDevice, sendOnlineStatus, sendStatus, setDevice, setDomain, togglePreviewMode } from "./state/app.actions";
import { environment } from "src/environments/environment";
import { SocketService } from "./services/socket.service";
import { CoreService } from "./services/core.service";
import { Device } from "./models/device.model";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as _ from 'lodash-es';



@Injectable({ providedIn: "root" })
export class AppFacade {

    constructor(
        private store: Store,
        private socket: SocketService,
        private Core: CoreService
    ) { }

    /**
     * * Set Device Device.
     */
    async setDeviceState() {
        try {
           
            let deviceInfo = await this.Core.getDeviceInfo();
            let type=environment.platforms.find(el=>el.name===(deviceInfo.platform as string).toLowerCase()).code;
            //let type = _.find(environment.platforms, pl => pl.name === (deviceInfo.platform as string).toLowerCase())['code'];
            console.log(JSON.stringify(deviceInfo))
            let device: Device = {
                platform: deviceInfo.platform,
                type: type,
                serial_no: deviceInfo.serial ?? deviceInfo.id,
                mac_address: deviceInfo.mac_address,
                exists: false,
                key: null,
                deviceCouldBeRegister: false,
                alert: ''
            }
            
            this.store.dispatch(setDevice({ payload: device }));
            return this.store.select(state => state);
        } catch (error) {
           console.log(error);           
        }
    }

    getStoreApplication(){
        return this.store.select(state => state) as any;
    }

    /**
     * * Set Domain to State
     * @param domain 
     * @returns Action setDomain from state
     */
    setDomain(domain: string) {
        return this.store.dispatch(setDomain({payload: domain}));
    }

    /**
   * * Enable debug websocket
   * @returns method
   */
    enableSocketDebug() {
        return this.socket.debugWebSocket();
    }

    /**
     * * Return if Device Exists or Not
     * @returns objervable boolean
     */
    deviceExists(): Observable<boolean> {
        return this.store.select((state: any) => state.application.device.exists);
    }

    /**
     * * Return Preview Mode (true/false)
     * @returns objervable boolean
     */
    isOnPreviewMode(): Observable<boolean> {
        return this.store.select((state: any) => state.application.previewMode);
    }

    /**
     * * Register Device throught state
     * @returns dispatch action 
     */
    registerDevice() {
        this.store.dispatch(getRegisterDevice());
        return this.store.select((state: any) => state.application.device);
    }

    /**
     * * Set Default Language
     * @param lang 
     * @returns method change language default
     */
    setDefaultLang(lang: string) {
        return this.Core.setDefaultLang(lang);
    }

    /**
     * * Send Online Status
     * @returns action from state that send online status
     */
    sendOnlineStatus() {
        //console.log('send online status')
        return this.store.dispatch(sendOnlineStatus());
    }

    /**
     * * Enables Socket Event Listener
     * @returns hook event from socket
     */
    enableSocketConnection() {
        
        return this.socket.initSocket();
    }

    /**
     * * Set Refresh TimeOut Event Listener
     * @param refreshTime 
     */
    setRefreshTimeout(refreshTime: string): void {
        let refreshTimeout: number;
        refreshTimeout = this.Core.refreshTimeout(refreshTime);
        console.log('setRefreshTimout: ' + refreshTime);
        setTimeout(() => {
            this.store.dispatch(sendStatus());
        }, refreshTimeout)
    }

    /**
     * * Enable Preview Mode
     */
    enablePreviewMode() {
        if (!_.isEqual((window as any).self, (window as any).top)) {
            this.store.dispatch(togglePreviewMode({payload: true}));
            setTimeout(() => {
                this.Core.killAppInstance('Preview time is over.');
            }, 120000);
        } else {
            this.Core.killAppInstance('Preview not allowed here.');
        }
    }

    /**
     * * Get Device Platform
     * @returns Observable platform
     */
    getDevicePlatform() {
        return this.store.select((state: any) => state.application.device.platform);
    }

    /**
     * * Get Device Platform
     * @returns Observable domain
     */
    getDomain(){
        return this.store.select((state:any)=>state.domain);
    }

    isDeviceOnline(){
        return this.store.select((state:any)=>state.online);
    }

    showInfo(){
        return this.store.dispatch(deviceShowInfo())
    }
}