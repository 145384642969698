import { Injectable } from '@angular/core';
import { AndroidFileService } from './android-file.service';
import { fileServiceFactory, fileServiceProvider } from './file.service.provider';
import { FilesService } from './files.service';
import { LGFileService } from './lgfile.service';
import * as CryptoJs from 'crypto-js';
import { from, Observable, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppFacade } from '../app.facade';



@Injectable({
  providedIn: 'root',

})

export class ApicacheService {

  constructor(private FileService: FilesService, private http: HttpClient, private store:Store) {

  }

  getCached(apiPath: string, body: string): Observable<any> {
    let hash = CryptoJs.MD5(apiPath + body).toString();
    console.log('Geting hash:' + hash);    
    return this.http.post<any>(apiPath, body, { observe: 'response' }).pipe(
      tap((response) => {
        //save the response in file
        console.info('we got endpoint response / saving localy');
        console.log('we got endpoint response / saving localy');
        this.saveCached(hash, response);
      }),
      catchError((err)=>{
        console.info('endpoint unreachable / serving local file');
        console.log('endpoint unreachable / serving local file');
        return from(this.FileService.fileExists(hash)).pipe(
          concatMap((res) => {
            //console.log(res);
            if (res)
              //file exists and we fetch it
              return from(this.FileService.getJsonFile(hash))
            //file does not exist - let's call the api            
          })
        )
      })
      ) 

  }

  //SAVE ONLY IF WE OBTAINED A LICENSE
  getCachedLicense(apiPath:string, body:string): Observable<any>{
    let hash = CryptoJs.MD5(apiPath + body).toString();
    console.log('Geting hash:' + hash);   
    
    return from(this.FileService.fileExists(hash)).pipe(
      concatMap((res) => {
        //console.log(res);
        if (res){
          //file exists and we fetch it          
          return from(this.FileService.getJsonFile(hash))
        }
        //file does not exist - let's call the api
        return this.http.post<any>(apiPath, body, { observe: 'response' }).pipe(
          tap((response) => {
            //save the response in file only if it does not contain error
            if (!response.body.error){
              this.saveCached(hash, response);
            }
          }))
      })
    )

  }

  async saveCached(hash: string, body: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      //let hash = CryptoJs.MD5(apiPath).toString();
      this.FileService.saveJsonFile(hash, body).then((result) => {
        resolve(true)
      }).catch((err) => resolve(false));
    })
  }

  async flush(){
    return new Promise<boolean>(resolve=>{
      this.FileService.deleteJsonFiles().then(()=>resolve(true));
    })
  }

  async flushMedia(){
    return new Promise<boolean>(resolve=>{
      console.info('flushing media files');
      this.FileService.deleteMediaFiles().then(()=>resolve(true));
    })
  }

  async flushLicense(){
    return new Promise<boolean>(resolve=>{
      this.FileService.deleteLicenseFile().then(()=>resolve(true));      
    })
  }
}
