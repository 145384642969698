


 export abstract class FilesService {

  constructor() { }

  public abstract getImage(path:string): Promise<string>;

  public abstract getVideo(path:string): Promise<string> ;

  public abstract saveImage(path:string):Promise<string>;

  public abstract saveVideo(path:string):Promise<string>;

  public abstract saveJsonFile(hash:string, body:any):Promise<void>;

  public abstract getJsonFile(hash:string):Promise<any>;

  public abstract fileExists(hash:string):Promise<boolean>;

  public abstract filePathExists(fullpath:string):Promise<boolean>;

  public abstract deleteJsonFiles():Promise<boolean>;

  public abstract deleteMediaFiles():Promise<boolean>;

  public abstract deleteLicenseFile():Promise<boolean>;

  public abstract openLogFile():Promise<any[]>;

  public abstract appendLogFile(row:any):Promise<boolean>;

  public abstract getLicenseFile():Promise<any>;

  public abstract saveLicenseFile(body:any):Promise<void>;

 

}

