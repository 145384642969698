import { environment } from 'src/environments/environment';
import { Component, isDevMode } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { AppFacade } from './app.facade';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { RegistrationService } from './services/registration.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    

    constructor(
        private AppFacade: AppFacade,
       
        private registrationService: RegistrationService,

    ) {
        //? Set Device Ready
        
        //this.registrationService.init();
        this.registrationService.applicationSetup();
    }
}


