
import { Store } from "@ngrx/store";
import { env } from "process";
import { environment } from "src/environments/environment";
import { AndroidFileService } from "./android-file.service";
import { CoreService } from "./core.service";
import { FilesService } from "./files.service";
import { LGFileService } from "./lgfile.service";
import { LocalStorageService } from "./localStorage.service";
import { TizenService } from "./tizen.service";



export let fileServiceFactory= (androidService:AndroidFileService, lgService:LGFileService, localStorage:LocalStorageService, tizenService:TizenService) => { 

        switch(environment.deviceType){
             case 'android':
                 return androidService;               
             case 'web':
                 return localStorage;       
            case 'tizen':
                return tizenService;         
             default:
                 return lgService;            
         }
}



export let fileServiceProvider = {
    provide: FilesService,
    useFactory: fileServiceFactory,
    deps: [
      AndroidFileService,
      LGFileService,
      LocalStorageService,
      TizenService,
      CoreService
    ]
  };