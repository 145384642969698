import { createAction, props } from "@ngrx/store";
import { Device } from "../models/device.model";
import { Layout } from "../models/layout.model";

export const setDevice = createAction('[Device] Set Device', props<{ payload: Device }>());
export const getRegisterDevice = createAction('[Device] Get Register');
export const setRegisterDevice = createAction('[Device] Register', props<{ payload: { exists: boolean, key?: string, register_device: boolean, alert?: string } }>());
export const getScreenLayout = createAction('[Screen] Get Layout');
export const setScreenLayout = createAction('[Screen] Set Layout', props<{ payload: Layout }>());
export const setWidgets = createAction('[Widgets] Add Widget', props<{ payload: any }>())
export const getLogo = createAction('[Logo Component] Get Logo');
export const getWeather = createAction('[Weather Component] Get Weather');
export const getCurrency = createAction('[Currency Component] Get Currency');
export const getContent = createAction('[Slider Component] Get Content', props<{ payload: { label: string, link: string } }>());
export const getVideo = createAction('[Video Component] Get Video');
export const getIframe = createAction('[Iframe Component] Get iframe');
export const setFullContent = createAction('[Widgets] Set Full Content');
export const getFullVideo = createAction('[Video Component] Get Full Video');
export const getFullCommercials = createAction('[Slider Component] Get Full Commercials');
export const sendStatus = createAction('[Device] Update Status');
export const sendOnlineStatus = createAction('[Device] Send Online Status');
export const togglePreviewMode = createAction('[Device] Toggle Preview Mode', props<{ payload: boolean }>());
export const setDomain = createAction('[Device] Set Domain', props<{ payload: string }>());
export const setFakeOrientention = createAction('[Device] Set Orientention', props<{ payload: string }>());
export const deviceOffline = createAction('[Device] Device offline');
export const deviceOnline = createAction('[Device] Device online');
export const deviceBackOnline = createAction('[Device] Device back online');
export const deviceFlushApiCache = createAction('[Device] Device flush api cache');
export const deviceFlushMedia = createAction('[Device] Device flush media');
export const deviceShowInfo = createAction('[Device] Device show Info');
export const releaseLicense = createAction('[License] Release license')