import { Injectable } from '@angular/core';
import { FilesService } from './files.service';


@Injectable({ providedIn: 'root' })
export class LocalStorageService extends FilesService{

  constructor() { 
    super()
  }

  public getImage(path: string):  Promise<string>  {
    //console.log('getting image from LG');    
    return new Promise(resolve => {
      resolve(path);
    });
  }

  public getVideo(path: string):  Promise<string>  {
    return new Promise(resolve => {
      resolve(path);
    });
  }

  public saveImage(path:string):Promise<string>{
    return new Promise(resolve => {
      resolve(path);
    });
  }

  public saveVideo(path:string):Promise<string>{
    return new Promise(resolve => {
      resolve(path);
    });
  }

  public saveJsonFile(hash: string, body: any):Promise<void>{
    return new Promise(resolve => {
        localStorage.setItem(hash,JSON.stringify(body));
        resolve();
    });
  }

  public getJsonFile(hash:string):Promise<any>{
    return new Promise(resolve => {      
      resolve(JSON.parse(localStorage.getItem(hash)));
    });
  }

  public fileExists(hash: string): Promise<boolean> {
    return new Promise(resolve => {      
      resolve(!!localStorage.getItem(hash));
    });
  }

  public filePathExists(fullpath: string): Promise<boolean> {
    return new Promise(resolve => {
      console.log(fullpath)
      resolve(!!localStorage.getItem(fullpath));
    });
  }

  public deleteJsonFiles(): Promise<boolean> {
    return new Promise(resolve => {          
      resolve(false);
    });
  }

  public deleteMediaFiles(): Promise<boolean> {
    return new Promise(resolve => {
      resolve(false);
    });
  }

  public appendLogFile(row: any): Promise<boolean> {
    return new Promise(resolve => {
      resolve(true);
    });
  }

  public openLogFile(): Promise<any[]> {
    return new Promise(resolve => {
      resolve([]);
    });
  }
  
  public getLicenseFile(): Promise<any> {
    return new Promise(resolve => {   
      console.log(localStorage.getItem('license/ocgpro.json'));
      resolve(JSON.parse(localStorage.getItem('license/ocgpro.json')));
    });
  }

  public saveLicenseFile(body: any): Promise<void> {
    return new Promise(resolve => {
      localStorage.setItem('license/ocgpro.json',JSON.stringify(body));
      resolve();
    });
  }

  public deleteLicenseFile(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      localStorage.removeItem('license/ocgpro.json');
      resolve(true);
    })
  }

}
