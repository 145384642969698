import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoreService } from './core.service';
import * as totp from 'totp-generator';
import { AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FilesService } from './files.service';
import { AppFacade } from '../app.facade';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import _ from 'lodash';


const keyRegeneration: number = 30;
const otpLength: number = 6;

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    constructor(private router: Router,
        private appFacade: AppFacade,
        private core: CoreService,
        private httpClient: HttpClient,
        private toastController: ToastController,
        private translate: TranslateService,
        private fileService: FilesService,
        private alertController: AlertController) { }

    private _otp = new Subject<string>();

    hash = "";
    deviceid;
    intervalTime: number = 60 * 1000; // min / sec / mil
    isOnlineInterval: any = null;

    public init() {
        this.core.getDeviceInfo().then((deviceInfo) => {
            this.deviceExists(deviceInfo).then((data) => {
                if (!!data) {
                    this.hash = data.two_fa_secret;
                    this.generateOtp();
                } else {
                    this.hashFetch(deviceInfo);
                }
            })
        })

    }
    public getOtp() {
        return this._otp?.asObservable()
    }
    private hashFetch(deviceInfo) {
        let body = {
            "name": deviceInfo.name ?? deviceInfo.platform + '-' + deviceInfo.id,
            "serial_number": deviceInfo.id,
            "version": environment.version,
            "registration_code": deviceInfo.id,
            "token": "token",
            "ip": "0.0.0.1",
            "mac": deviceInfo.mac_address,
            "type": 6,
            "status": 1
        }
        this.deviceExistsUnlicensed(deviceInfo).then((registration) => {
            console.log('existing registration');
            console.log(registration);
            if (!!registration) {
                this.hash = registration.two_fa_secret;
                this.deviceid = registration.id;
                console.log(this.hash);
                this.generateOtp();
                setInterval(() => { this.generateOtp() }, keyRegeneration * 1000);
            } else {
                let httpHeaders = new HttpHeaders();
                httpHeaders = httpHeaders.set('Content-Type', 'application/json').set("Authorization", "Basic " + btoa("deviceregister:c84fa23793e50cd6592b32c15bb51b554d465e99"));
                this.httpClient.post<any>('https://titan.ocg.gr/api/v1/ocg/devices', body, { headers: httpHeaders }).subscribe((data) => {
                    console.log(data);
                    if (!!data.data && !!data.data.two_fa_secret && data.status) {
                        this.hash = data.data.two_fa_secret;
                        this.deviceid = data.data.id;
                        console.log(this.hash);
                        this.generateOtp();
                        setInterval(() => { this.generateOtp() }, keyRegeneration * 1000);
                    } else {
                        //TODO failtoast
                        this.alertController.create({
                            header: 'Synchronization failed',
                            message: 'No device registered',
                            buttons: [{ text: "OK", id: 'okbtn' }]
                        }).then((alert) => alert.present().then(() => (document.querySelector("#okbtn") as HTMLElement).focus()))

                    }
                    //this._opt.next('000 000');
                }, (error) => {
                    let alert = this.alertController.create({
                        header: 'Synchronization failed',
                        message: 'No connection. ' + (isDevMode()) ? JSON.stringify(error) : "",
                        buttons: [{ text: "OK", id: 'okbtn' }]
                    }).then((alert) => alert.present().then(() => (document.querySelector("#okbtn") as HTMLElement).focus()))
                })
            }
        })

        // console.log('lets get the otp');
        // this.generateOtp();
        // setTimeout(() => {this.generateOtp()}, 30000);

    }

    public generateOtp() {
        console.log(this.hash);
        let newotp = totp(this.hash, { digits: otpLength, algorithm: '' });
        this._otp.next(newotp as string);
    }

    public getLocalConfig() {
        return new Promise<any>((resolve) => {
            this.fileService.filePathExists('license/ocgpro.json').then((res) => {
                if (res) {
                    this.fileService.getLicenseFile().then((license) => {
                        resolve(license);
                    })
                } else {
                    console.log('no license file');
                    resolve({ 'error': 'license does not exist' });
                }
            })
        });
    }

    public deviceExistsUnlicensed(deviceInfo) {
        return new Promise<any>((resolve) => {
            let httpHeaders = new HttpHeaders();
            httpHeaders = httpHeaders.set('Content-Type', 'application/json').set("Authorization", "Basic " + btoa("deviceregister:c84fa23793e50cd6592b32c15bb51b554d465e99"));

            this.httpClient.get<any>('https://titan.ocg.gr/api/v1/ocg/device/check?serialNumber=' + deviceInfo.id, { headers: httpHeaders }).subscribe((data) => {
                let obj=data.data.find((el) => {return el.remote_id===null });
                if (data.data.length > 0 && !!obj) {
                    resolve(obj);
                } else {
                    resolve(null);
                }
            }, (err) => {
                this.toastController.create({
                    message: JSON.stringify(err),
                    duration: 8000,
                    position: 'middle',
                    cssClass: 'preview-toast'
                }).then((toast) =>
                    toast.present()
                )
            });
        })
    }


    public deviceExists(deviceInfo) {
        return new Promise<any>((resolve) => {
            let httpHeaders = new HttpHeaders();
            httpHeaders = httpHeaders.set('Content-Type', 'application/json').set("Authorization", "Basic " + btoa("deviceregister:c84fa23793e50cd6592b32c15bb51b554d465e99"));

            this.httpClient.get<any>('https://titan.ocg.gr/api/v1/ocg/device/check?serialNumber=' + deviceInfo.id, { headers: httpHeaders }).subscribe((data) => {
                //console.log(data);
                if (data.data.length > 0 && data.data.find((el) => { el.remote_id != null }) != null) {
                    resolve(data.data.find((el) => { el.remote_id != null }));
                } else {
                    resolve(null);
                }
            }, (err) => {
                this.toastController.create({
                    message: JSON.stringify(err),
                    duration: 8000,
                    position: 'middle',
                    cssClass: 'preview-toast'
                }).then((toast) =>
                    toast.present()
                )
            });
        })
    }

    //get license based on previous logged id
    public retrieveLicense() {
        return new Promise<any>((resolve) => {

            console.log('retrieving license based on id ' + this.deviceid);
            let httpHeaders = new HttpHeaders();
            httpHeaders = httpHeaders.set('Content-Type', 'application/json').set("Authorization", "Basic " + btoa("deviceregister:c84fa23793e50cd6592b32c15bb51b554d465e99"));

            this.httpClient.get<any>('https://titan.ocg.gr/api/v1/ocg/device/' + this.deviceid, { headers: httpHeaders }).subscribe((data) => {

                if (!!data.data.instance && data.data.instance != '') {
                    console.log('device synced - saving license')
                    this.fileService.saveLicenseFile(data).then(() => {
                        console.log('device synced - setting domain')
                        resolve(data)
                    }).catch((err) => { alert('eror'); console.log(err) })
                } else {
                    //resolve
                    resolve({ error: true, message: "This device is not registered." })
                }
            }, (err) => {
                this.toastController.create({
                    message: this.translate.instant(JSON.stringify(err)),
                    duration: 8000,
                    position: 'middle',
                    cssClass: 'preview-toast'
                }).then((tst) => {
                    tst.present();
                    resolve(err);
                }
                )
            })
        })
    }



    public applicationSetup() {
        this.appFacade.setDeviceState().then((state) => {
            console.log(state);
            state.pipe(take(1)).subscribe((res: any) => {
                this.getLocalConfig().then((response) => {
                    console.log("instance");
                    console.log(response);
                    if (!!response.error || response == null || response.data.instance == null || response.data.instance === "") {
                        console.log('no license');
                        this.router.navigate(['sync']);
                    } else {
                        let host = environment.protocol + response.data.instance;
                        this.appFacade.setDomain(host);
                        console.log('set host:' + host);
                        //router.navigate(['prefetch']);                   

                        if (!!res.application.device) {
                            if (isDevMode() && !_.isEqual(res.application.device.type, 100)) {
                                //? Enable Socket Debug Terminal
                                //this.appFacade.enableSocketDebug();
                            }
                            //this.appFacade.enableSocketDebug();

                            //? Enable Preview Mode
                            if (!_.isEmpty((window as any).unique_id)) {
                                this.appFacade.enablePreviewMode();
                            }

                            //? Enable Socket Connection
                            //if (!_.includes(host, 'http://')) {
                            // this.appFacade.getDomain().subscribe((dm) =>
                            //     this.appFacade.enableSocketConnection()
                            // )

                            //}

                            //? Set default Lang
                            this.appFacade.setDefaultLang('en');

                            //? Register Device
                            this.appFacade.registerDevice().pipe(
                                filter(state => _.has(state, 'key') && !_.isNull(state.key)),
                                take(1)
                            ).subscribe(result => {
                                console.log('register device');
                                //? Send Online Status;
                                console.log(result);
                                this.appFacade.sendOnlineStatus();                                
                                if (_.isNull(this.isOnlineInterval)) {
                                    this.isOnlineInterval = setInterval(() => {
                                        try {
                                            console.log('get online status interval')
                                            this.appFacade.sendOnlineStatus();
                                        } catch (ex) {
                                            console.log(ex);
                                        }
                                    }, this.intervalTime);
                                }
                                console.log(result);
                                if (result.exists) {
                                    //router.navigate(['screen']);
                                 
                                    this.router.navigate(['prefetch'])
                                } else {
                                    //alert('no channel');
                                    this.alertController.create({
                                        header: 'No channel present',
                                        message: 'Please assign a channel to this device and click "Retry"',
                                        buttons: [{ text: 'Retry', id: 'okbtn', role: 'cancel', handler: () => { this.applicationSetup() } }]
                                    }).then((alert) => alert.present().then(() => (document.querySelector("#okbtn") as HTMLElement).focus()))
                                    //this.router.navigate(['sync']);
                                }
                            });
                        }
                    }
                })
            })
        })
    }
}