import { DEFAULT_TIMEOUT, RequestInterceptor } from './inteceptors/request.interceptor';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { RouteReuseStrategy } from '@angular/router';
import { appReducer } from './state/app.reducer';
import { AppEffects } from './state/app.effect';
import { AppComponent } from './app.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ErrorHandler, NgModule } from '@angular/core';
import { PrefetchPage } from './prefetch/prefetch.page';
import { FilesService } from './services/files.service';
import { fileServiceFactory } from './services/file.service.provider';
import { AndroidFileService } from './services/android-file.service';
import { LGFileService } from './services/lgfile.service';
import { Device } from '@ionic-native/device/ngx';
import { LocalStorageService } from './services/localStorage.service';
import { TizenService } from './services/tizen.service';




export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    declarations: [AppComponent,PrefetchPage],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot({ application: appReducer }),
        EffectsModule.forRoot([AppEffects]),
        StoreDevtoolsModule.instrument({ maxAge: 15, logOnly: environment.production }),
        AppRoutingModule,
    ],
    providers: [    

        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        //{ provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        // { provide: ErrorHandler, useClass: MyErrorHandler },
        { provide: DEFAULT_TIMEOUT, useValue: 120000 },
        {provide:FilesService, useFactory: fileServiceFactory,deps: [AndroidFileService,
              LGFileService,LocalStorageService, TizenService
            ],
        },      
        Device, 
        InAppBrowser,
        AndroidFullScreen,     
        
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
