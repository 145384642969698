
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FilesService } from './files.service';
import { HttpClient } from '@angular/common/http';
import { each } from 'jquery';

@Injectable({ providedIn: 'root' })



export class TizenService extends FilesService {
    tizen = (window as any).tizen;
    constructor(private http: HttpClient) {
        super()
    }

    public getImage(path: string): Promise<string> {
        //console.log('getting image from LG');

        return new Promise((resolve) => {
            let url = path;
            let filename = 'images/' + path.replace(/^.*[\\\/]/, '')
            console.info('Image requested:' + filename)
            console.info('original path is:' + path)
            //console.info('is documents '+this.tizen.filesystem.isDirectory('documents'));
            //console.info('is images '+this.tizen.filesystem.isDirectory('documents/images'));
            //console.info('is file '+this.tizen.filesystem.isFile(filename));
            try {
                if (this.tizen.filesystem.isFile(filename)) {
                    console.info('Image exists:' + this.tizen.filesystem.toURI(filename))
                    resolve('/home/owner/content/' + filename.replace('images', 'Images'));
                } else {
                    console.info('Image does not exist we have to save it: ' + filename)
                    this.saveImage(path).then((fname) => {
                        if (fname.includes('http')) {
                            resolve(fname)
                        } else {
                            resolve('/home/owner/content/' + fname.replace('images', 'Images'));
                        }

                    })
                }
            } catch (ex) {
                console.info(JSON.stringify(ex));
                this.saveImage(path).then((fname) => {
                    if (fname.includes('http')) {
                        resolve(fname)
                    } else {
                        resolve('/home/owner/content/' + fname.replace('images', 'Images'));
                    }
                })
            }


        })
    }

    public getVideo(path: string): Promise<string> {
        return new Promise((resolve) => {
            let url = path;
            let filename = 'videos/' + path.replace(/^.*[\\\/]/, '')
            console.info('Video requested:' + filename)
            console.info('original path is:' + path)
            //console.info('is documents '+this.tizen.filesystem.isDirectory('documents'));
            console.info('is videos ' + this.tizen.filesystem.isDirectory('videos'));
            //console.info('is file '+this.tizen.filesystem.isFile(filename));
            try {
                if (this.tizen.filesystem.isFile(filename)) {
                    console.info('Video exists:' + this.tizen.filesystem.toURI(filename))
                    resolve('/home/owner/content/' + filename.replace('videos', 'Videos'));
                } else {
                    console.info('Video does not exist we have to save it: ' + filename)
                    this.saveVideo(path).then((fname) => {
                        if (fname.includes('http')) {
                            resolve(fname);
                        } else
                            resolve('/home/owner/content/' + fname.replace('videos', 'Videos'));
                    }
                    )
                }
            } catch (ex) {
                console.info(JSON.stringify(ex));
                console.info('Video does not exist we have to save it: ' + path)
                this.saveVideo(path).then((fname) => {
                    if (fname.includes('http')) {
                        resolve(fname);
                    } else
                        resolve('/home/owner/content/' + fname.replace('videos', 'Videos'));
                }
                )
            }


        })
    }

    public saveImage(path: string): Promise<string> {

        return new Promise((resolve) => {
            let filename = 'images/' + path.replace(/^.*[\\\/]/, '');
            console.info('Image to be saved:' + path)

            this.http.get(path, { responseType: 'blob' }).subscribe((blob: Blob) => {
                console.info('Blob image ready')
                //console.log(blob);
                try {
                    var fileHandleWrite = this.tizen.filesystem.openFile(filename, 'w');
                    fileHandleWrite.writeBlob(blob);
                    fileHandleWrite.close();
                    console.info('blob image written');
                    resolve(filename);
                } catch (ex) {
                    console.info('Problem on saving file exists:' + JSON.stringify(ex))
                    resolve(path);
                }
            }, (err) => {
                console.info('error getting blob: ' + JSON.stringify(err))
                resolve(path);
            })
        })
    }

    public saveVideo(path: string): Promise<string> {
        return new Promise((resolve) => {
            let filename = 'videos/' + path.replace(/^.*[\\\/]/, '');
            console.info('Video to be saved:' + path)

            this.http.get(path, { responseType: 'blob' }).subscribe((blob: Blob) => {
                console.info('Blob video ready')
                //console.log(blob);
                try {
                    var fileHandleWrite = this.tizen.filesystem.openFile(filename, 'w');
                    fileHandleWrite.writeBlob(blob);
                    fileHandleWrite.close();
                    console.info('blob video written');
                    resolve(filename);
                } catch (ex) {
                    console.info('Problem on saving video exists:' + JSON.stringify(ex))
                    resolve(path);
                }
            }, (err) => {
                console.info('error getting blob: ' + JSON.stringify(err))
                resolve(path);
            })
        })
    }

    public saveJsonFile(hash: string, body: any): Promise<void> {
        return new Promise(resolve => {
            localStorage.setItem(hash, JSON.stringify(body));
            resolve();
        });
    }

    public getJsonFile(hash: string): Promise<any> {
        return new Promise(resolve => {
            resolve(JSON.parse(localStorage.getItem(hash)));
        });
    }

    public fileExists(hash: string): Promise<boolean> {
        return new Promise(resolve => {
            resolve(!!localStorage.getItem(hash));
        });
    }

    public filePathExists(fullpath: string): Promise<boolean> {
        return new Promise(resolve => {
            console.log(fullpath)
            resolve(!!localStorage.getItem(fullpath));
        });
    }

    public deleteJsonFiles(): Promise<boolean> {
        return new Promise(resolve => {
            resolve(false);
        });
    }

    public deleteMediaFiles(): Promise<boolean> {
        return new Promise(resolve => {
            this.deleteVideos().then((resp)=>{
                resolve(resp);
            })
            //resolve(false);
        });
    }

    public deleteVideos(): Promise<boolean> {
        return new Promise(resolve => {
            //resolve(false);
            console.info('Delete videos');
            this.tizen.filesystem.listDirectory('videos', (vids) => {
                console.info('Delete file '+JSON.stringify(vids));
                vids.forEach((vid) => {
                    try {
                        console.info('Delete file '+vids);
                        this.tizen.filesystem.deleteFile('videos/' + vid);
                    } catch (ex) {
                        console.info('Error deleting file '+vid);
                    }
                })
            })
        });
    }

    public appendLogFile(row: any): Promise<boolean> {
        return new Promise(resolve => {
            resolve(true);
        });
    }

    public openLogFile(): Promise<any[]> {
        return new Promise(resolve => {
            resolve([]);
        });
    }

    public getLicenseFile(): Promise<any> {
        return new Promise(resolve => {
            resolve(JSON.parse(localStorage.getItem('license/ocgpro.json')));
        });
    }

    public saveLicenseFile(body: any): Promise<void> {
        return new Promise(resolve => {
            localStorage.setItem('license/ocgpro.json', JSON.stringify(body));
            resolve();
        });
    }

    public deleteLicenseFile(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            localStorage.removeItem('license/ocgpro.json');
            resolve(true);
        })
    }

}
