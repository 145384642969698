import { environment } from "src/environments/environment";
import { deviceFlushMedia, sendStatus, deviceShowInfo, releaseLicense } from "../state/app.actions";
import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";
import * as io from 'socket.io-client';
import { Store } from "@ngrx/store";
import * as _ from 'lodash-es';

@Injectable({ providedIn: "root" })
export class SocketService {
    socket: any;
    domain
    constructor(
        private store: Store

    ) {
        this.store.select((state: any) => state.application.domain).subscribe((domain) => {
            console.log('this.domain=' + domain)
            this.domain = domain;
        })
    }

    /**
     * * Initial debug websocket
     * @returns promise boolean
     */
    debugWebSocket(): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            let connection = new WebSocket('ws://192.168.128.123:1337');
            connection.onopen = () => {
                console.info = (message: any) => {
                    connection.send('OCGPRO: ' + message);
                }
                window.onerror = (e, url, lineno, colno, err: any) => {
                    connection.send('OCGPRO: ' + e.toString() + ' ' + url + ' ' + lineno + ' ' + colno + '' + err.message);
                }
                console.info(navigator.userAgent);
                console.info('------ Debug Websocket Ready ------');
                connection.onerror = () => {
                    console.info('------ Debug Websocket Error ------');
                    connection.close();
                    resolve(false);
                };
                resolve(true);
            }
        })
        return promise;
    }

    initSocket() { 
        
        this.store.select((state: any) => state.application).subscribe((application) => {
            console.log(1);
            console.log(application);
            console.log(2);
            if(!!application.layout?.tvbox_id){
            console.log('this.domain=' + application.domain)
            this.domain = application.domain;
            let socketChannel: string;
            console.log('init socket on ' + this.domain)
           
            this.socket = io(this.domain,{
                "reconnectionDelay": 20000,
                "reconnectionDelayMax": 20000,
            });

            
            
            socketChannel = this.domain.replace('https://','').replace('http://','') + '_smartTv';
           
            let tvbox_id = application.layout?.tvbox_id;
            
            console.log("tvbox:"+tvbox_id);
            //? Socket Connection
            this.socket.on('connect', () => {
                console.log('Connected to: ' + this.domain);
                console.log('Socket ID: ' + this.socket.id);
                this.socket.emit('joinRoom', this.domain.replace('https://','').replace('http://',''));
                //? Socket Boardcast Channel
                this.socket.on(socketChannel, (datarcvd: any) => {
                    console.info(JSON.stringify(datarcvd));
                    datarcvd = JSON.parse(datarcvd);
                    //console.log(datarcvd);
                    if (_.isEqual(tvbox_id, datarcvd.tvbox_id)) {
                        if (datarcvd.action == 'Refresh') {
                            this.store.dispatch(sendStatus());
                        } else if (datarcvd.action == 'Clear') {
                            //this.clearVideos(); //! this just removed data from localstorage.. now we dont have anything on localstorage.. everything is in state.                     
                            this.store.dispatch(deviceFlushMedia());
                        } else if (datarcvd.action == 'Showinfo'){      
                            console.log('show info request required');                      
                            this.store.dispatch(deviceShowInfo());
                        } else if(datarcvd.action == 'ReleaseLicense'){
                            console.log('releasing license')
                            this.store.dispatch(releaseLicense())
                        }
                    }
                });
            });
        }
            this.socket.on('connect_error',(err)=>{
                console.log(err);
            })
            this.socket.on('disconnect', () => {
                console.info('disconnected from server');
                this.socket.removeAllListeners();
                this.socket.close()
            });
        })
        



    }
}