import { getFullCommercials, getFullVideo, setRegisterDevice, setScreenLayout, setWidgets } from "./app.actions";
import { catchError, filter, flatMap, map, mergeMap, switchMap, take, withLatestFrom } from "rxjs/operators"
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";
import packageInfo from '../../../package.json';
import { Injectable } from "@angular/core";
import { combineLatest, from, of } from "rxjs";
import { Store } from "@ngrx/store";
import * as _ from 'lodash-es';
import { NavController, ToastController } from "@ionic/angular";
import { ApicacheService } from "../services/apicache.service";
import { state } from "@angular/animations";
import * as AppActions from "./app.actions"



@Injectable()
export class AppEffects {



    private hostUrl: string;

    /**
     * * Register Device to OCG & update state
     */
    //to be refactored - cache service should be saving license file
    registerDevice$ = createEffect(() => this.actions$.pipe(
        ofType('[Device] Get Register'),
        withLatestFrom(this.store$.select((state: any) => state.application.device)),
        switchMap(([action, state]) => {
            let body = `&serial_no=${state.serial_no}&mac_address=${state.mac_address}&device_type=${state.type}`;          
            //return this.http.post<any>(`${this.hostUrl}tvbox/create-smart-tv-pro-channel`, body, { observe: 'response' });        
            return this.cacheService.getCached(`${this.hostUrl}tvbox/create-smart-tv-pro-channel`, body)
        }),
        map(res => {
            console.log(res);
            if (res.body.error) {
                return setRegisterDevice({ payload: { exists: !!(window as any).unique_id ? true : res.body.exists, key: (window as any).unique_id, register_device: res.body.register_device, alert: res.body.alert} });
            }
            return setRegisterDevice({ payload: { exists: res.body.exists, key: res.body.key, register_device: res.body.register_device} })
        })
    ));

    /**
     * * Get Screen Layout & update state 
     */
    screenLayout$ = createEffect(() => this.actions$.pipe(
        ofType('[Screen] Get Layout'),
        withLatestFrom(this.store$.select((state: any) => state.application.device)),
        switchMap(([action, state]) => {
            let body = `&id=${state.key}`;
            //return this.http.post<any>(`${this.hostUrl}channel/tvboxlayout-plus`, body, { observe: 'response' });
            return this.cacheService.getCached(`${this.hostUrl}channel/tvboxlayout-plus`, body)
        }),
        map((res) => {
            console.log(res)
            if (_.isEqual(res.status, 200)) {
                return setScreenLayout({ payload: res.body[0] });
            }
            return setScreenLayout({ payload: null });
        })
    ));

    /**
     * * Get Logo for Logo Widget
     */
    widgetLogo$ = createEffect(() => this.actions$.pipe(
        ofType('[Logo Component] Get Logo'),
        withLatestFrom(this.store$.select((state: any) => state.application.layout)),
        switchMap(([action, state]) => {
            //return this.http.post<any>(`${this.hostUrl}channel-content/getpluscontent&kind=8&checked=1&fullscreen=0&channel_id=${state.channelid}`, '', { observe: "response" });
            return this.cacheService.getCached(`${this.hostUrl}channel-content/getpluscontent&kind=8&checked=1&fullscreen=0&channel_id=${state.channelid}`, '')
        }),
        map((res) => {
            if (_.isEqual(res.status, 200)) {
                let widget: any = [];
                widget['logo'] = res.body[0].image

                return setWidgets({ payload: widget });
            }
            return setWidgets({ payload: null });
        })
    ));

    /**
     * * Get Weather for Weather Widget
     */
    widgetWeather$ = createEffect(() => this.actions$.pipe(
        ofType('[Weather Component] Get Weather'),
        withLatestFrom(this.store$.select((state: any) => state.application.layout)),
        switchMap(([action, state]) => {
            let body: string = `&id=${state.channelid}`
            return this.cacheService.getCached(`${this.hostUrl}widgets/get-smart-tv-pro-weather`, body)
            
        }),
        map((res) => {
            if (_.isEqual(res.status, 200)) {
                let widget: any = [];
                widget['weather'] = JSON.parse(res.body)[0].forecast.simpleforecast.forecastday;

                return setWidgets({ payload: widget });
            }
            return setWidgets({ payload: null });
        })
    ));

    /**
    * * Get Currency for Currency Widget
    */
    widgetCurrency$ = createEffect(() => this.actions$.pipe(
        ofType('[Currency Component] Get Currency'),
        withLatestFrom(this.store$.select((state: any) => state.application.layout)),
        switchMap(([action, state]) => {
            let body: string = `&id=${state.channelid}`
            return this.cacheService.getCached(`${this.hostUrl}widgets/getcurrency-plus`, body)
            //return this.http.post<any>(`${this.hostUrl}widgets/getcurrency-plus`, body, { observe: "response" });
        }),
        map((res) => {
            if (_.isEqual(res.status, 200)) {
                let widget: any = [];
                widget['currency'] = res.body[0];

                if (!_.isNull(widget.currency.commition)) {
                    widget.currency.price1 = widget.currency.price1 - (widget.currency.commission / 100 * widget.currency.price1);
                    widget.currency.price2 = widget.currency.price2 - (widget.currency.commission / 100 * widget.currency.price2);
                }

                widget.currency.price1 = _.isNull(widget.currency.price1) ? '0.0' : widget.currency.price1;
                widget.currency.price2 = _.isNull(widget.currency.price2) ? '0.0' : widget.currency.price2;

                return setWidgets({ payload: widget });
            }
            return setWidgets({ payload: null });
        })
    ));

    /**
     * * Get Content for Slider Widget
     */
    widgetSlider$ = createEffect(() => this.actions$.pipe(
        ofType('[Slider Component] Get Content'),
        withLatestFrom(this.store$.select((state: any) => state.application.layout)),
        flatMap(([action, state]) => {
            let channel_id = !_.isEqual((action as any).payload.label, 'Twitter') && !_.isEqual((action as any).payload.label, 'News') && !_.isEqual((action as any).payload.label, 'Trip_Advisor') ? state.channelid : '';
            let body = _.isEqual((action as any).payload.label, 'Twitter') || _.isEqual((action as any).payload.label, 'News') || _.isEqual((action as any).payload.label, 'Trip_Advisor') ? `&id=${state.channelid}` : '';
            return combineLatest([
                of(action),
                this.cacheService.getCached(`${this.hostUrl}${(action as any).payload.link}${channel_id}`, body)
                //this.http.post<any>(`${this.hostUrl}${(action as any).payload.link}${channel_id}`, body, { observe: "response" }).toPromise()
            ])
        }),
        map(([action, res]) => {
            if (_.isEqual(res.status, 200)) {
                let widget: any = [];
                widget[(action as any).payload.label] = res.body;
                return setWidgets({ payload: widget });
            }
            return setWidgets({ payload: null });
        })
    ));

    /**
    * * Get Video for Video Widget
    */
    widgetVideo$ = createEffect(() => this.actions$.pipe(
        ofType('[Video Component] Get Video'),
        withLatestFrom(this.store$.select((state: any) => state.application.layout)),
        flatMap(([action, state]) => {
            let body: string = `&id=${state.channelid}`
            //return this.http.post<any>(`${this.hostUrl}videos/getvideos-plus`, body, { observe: "response", });
            return this.cacheService.getCached(`${this.hostUrl}videos/getvideos-plus`, body)
        }),
        map((res) => {
            if (_.isEqual(res.status, 200)) {
                let widget: any = [];
                widget['video'] = res.body;

                return setWidgets({ payload: widget });
            }
            return setWidgets({ payload: null });
        })
    ));

    /**
    * * Get Fullscreen Video for Video Widget
    */
    widgetFullVideo$ = createEffect(() => this.actions$.pipe(
        ofType('[Video Component] Get Full Video'),
        withLatestFrom(this.store$.select((state: any) => state.application.layout)),
        flatMap(([action, state]) => {
            let body: string = `&id=${state.channelid}`
            //return this.http.post<any>(`${this.hostUrl}videos/getfullvideos-plus`, body, { observe: "response" });
            return this.cacheService.getCached(`${this.hostUrl}videos/getfullvideos-plus`, body)
        }),
        map((res) => {
            if (_.isEqual(res.status, 200)) {
                let widget: any = [];
                widget['FullVideos'] = res.body;

                return setWidgets({ payload: widget });
            }
            return setWidgets({ payload: null });
        })
    ));

    /**
     * * Get Full Commercials
     */
    widgetFullCommercials$ = createEffect(() => this.actions$.pipe(
        ofType('[Slider Component] Get Full Commercials'),
        withLatestFrom(this.store$.select((state: any) => state.application.layout)),
        flatMap(([action, state]) => {
            //return this.http.post<any>(`${this.hostUrl}channel-content/getpluscontent&kind=1&checked=1&fullscreen=1&channel_id=${state.channelid}`, '', { observe: "response" });
            return this.cacheService.getCached(`${this.hostUrl}channel-content/getpluscontent&kind=1&checked=1&fullscreen=1&channel_id=${state.channelid}`, '')
        }),
        map((res) => {
            if (_.isEqual(res.status, 200)) {
                let widget: any = [];                
                widget['FullCommercials'] = res.body;

                return setWidgets({ payload: widget });
            }
            return setWidgets({ payload: null });
        })
    ));

    /**
     * * Get Iframe links for Iframe Widget
     */
    widgetIframe$ = createEffect(() => this.actions$.pipe(
        ofType('[Iframe Component] Get iframe'),
        withLatestFrom(this.store$.select((state: any) => state.application.layout)),
        switchMap(([action, state]) => {
            let body: string = `&id=${state.channelid}`
            //return this.http.post<any>(`${this.hostUrl}widgets/getiframe-plus`, body, { observe: "response" });
            return this.cacheService.getCached(`${this.hostUrl}widgets/getiframe-plus`, body);
        }),
        map((res) => {
            if (_.isEqual(res.status, 200)) {
                let widget: any = [];
                widget['iframe'] = res.body;

                return setWidgets({ payload: widget });
            }
            return setWidgets({ payload: null });
        })
    ));

    /**
     * * Get full content (Commercials, Video)
     */
    setFullContent$ = createEffect(() => this.actions$.pipe(
        ofType('[Widgets] Set Full Content'),
        mergeMap((action) => {
            return [
                getFullVideo(),
                getFullCommercials()
            ]
        })
    ));

    /**
     * * Send Status to OCG
     */
    sendStatus$ = createEffect(() => this.actions$.pipe(
        ofType('[Device] Update Status'),
        withLatestFrom(this.store$.select((state: any) => state.application.device)),
        switchMap(([action, state]) => {
            let body: string = `&status=1&unique_id=${state.key}&version=${packageInfo.version}&mac_address=${state.mac_address}&ip_address${state.ip_address}`;
            return this.http.post<any>(`${this.hostUrl}tvbox/updatestatus-plus`, body, { observe: "response" });
        }),
        map((res) => {
            if (_.isEqual(res.status, 200)) {
                this.cacheService.flush().then(()=>{(document as any).location='index.html'})    
               
            }
        })
    ), { dispatch: false });


     /**
     * * Send information toast
     */
    deviceShowInfo$ = createEffect(() => this.actions$.pipe(
        ofType('[Device] Device show Info'),
        withLatestFrom(this.store$.select((state: any) => state.application)),
        map(([action, state]) => {          
           this.toastController.create({
            message: 'Serial: '+state.device.serial_no+'<br/>Domain: '+state.domain+'<br/>Channel: '+state.layout.channelname,
            duration: 8000,
            position: 'bottom',
            cssClass: 'preview-toast',
            color:'warning'
        }).then((toast)=>toast.present())
        
        })
    ), { dispatch: false });

    /*
    ** Flush media from local folders
    */
    flushMedia$ = createEffect(() => this.actions$.pipe(
        ofType('[Device] Device flush media'),
        withLatestFrom(this.store$.select((state: any) => state.application.device)),
        switchMap(([action,state])=>{
            return from(this.cacheService.flushMedia().then(()=>{(document as any).location='index.html'}));
        })        
    ), { dispatch: false });

    /**
     * * Send Online Status to OCG
     */
    sendOnlineStatus$ = createEffect(() => this.actions$.pipe(
        ofType('[Device] Send Online Status'),
        withLatestFrom(this.store$.select((state: any) => state.application)),
        switchMap(([action, state]) => {
            let body: string = `&mac=${state.device.key}`;
            console.log('device probing for network');
            return this.http.post<any>(`${this.hostUrl}tvbox/isonline-plus`, body, { observe: "response" }).pipe(
                map((res)=>{
                    if (!_.isEqual(res.status, 200)) {
                        console.log('device offline')
                        this.store$.dispatch(AppActions.deviceOffline())
                    }else{
                        if(!state.online){
                            console.log('device back online')
                            this.store$.dispatch(AppActions.deviceBackOnline())
                        }else{
                            console.log('device was and is online')
                        }                   
                    }
                    return res;
                }),
                catchError((error)=>{
                    this.store$.dispatch(AppActions.deviceOffline())
                    return error
                })
            )
        }),
        
    ), { dispatch: false});

     /**
     * * Device is back online / flush app to retrieve new data
     */
    backOnline$ = createEffect(()=>this.actions$.pipe(
        ofType('[Device] Device back online'),
        withLatestFrom(this.store$.select((state: any) => state.application.device)),
        switchMap(([action,state])=>{
            return from(this.cacheService.flush().then(()=>{(document as any).location='index.html'}));
        })
    ),{dispatch:false})

    /**
     * * Release device license
     */

    releaseLicense = createEffect(()=>this.actions$.pipe(
        ofType('[License] Release license'),
        withLatestFrom(this.store$.select((state: any) => state.application.device)),
        switchMap(([action,state])=>{                   
            return from(this.cacheService.flushLicense().then(()=>{(document as any).location='index.html'}));
        })
    ),{dispatch:false})


    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store$: Store,
        private navControl: NavController,
        private cacheService:ApicacheService,
        private toastController:ToastController
    ) {
        store$.select((state: any) => state.application).pipe(
            filter(state => !_.isNull(state.domain)),
            take(1)
        ).subscribe(result => {
           this.hostUrl = `${result.domain}/web/index.php?r=`;
        })
    }
}