import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, timeout } from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({ providedIn: 'root' })
export class RequestInterceptor implements HttpInterceptor {
    constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('titan') < 0) {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `pass=${window.atob(environment.passKey)}${req.body}`
            })
        }

            const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
            const timeoutValueNumeric = Number(timeoutValue);

            return next.handle(req).pipe(
                timeout(timeoutValueNumeric),
                catchError((err) => {
                    const error = err.error?.message || err.statusText || err;
                    return throwError(error);
                })
            );
        } 
    }

