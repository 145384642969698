import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PrefetchPage } from './prefetch/prefetch.page';

const routes: Routes = [
  {
    path: 'sync',
    loadChildren: () => import('./sync/sync.module').then( m =>  {return m.SyncPageModule})
  },
  {
    path: 'screen',
    loadChildren: () => import('./screen/screen.module').then( (m) => {return  m.ScreenPageModule})
  },
  {path:'prefetch',
  component: PrefetchPage
  
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
